.carousel-wrap {
  height: 400px;
  margin: 0 auto;
  position: relative;
}

.window {
  background: #222;
  overflow: hidden;
  position: relative;
}

#carousel {
  position: relative;
  top: 0;
  width: 10000px;
}

.slide {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: center;
  background-color: black;
}

.mdi-chevron-left,
.mdi-chevron-right {
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  color: rgba(0, 0, 0, 0.589);
  cursor: pointer;
  cursor: pointer;
  font-size: 14pt !important;
  font-weight: bold;
  padding: 10px 15px;
  position: absolute;
  background-color: rgba(0, 255, 255, 0.445);
}

.mdi-chevron-left {
  left: 0;
  border-radius: 0px 30px 0px 0px;
  border-top: solid 1px #9c0039;
  border-right: solid 1px #9c0039;
}

.mdi-chevron-right {
  right: 0;
  border-radius: 30px 0px 0px 0px;
  border-top: solid 1px #9c0039;
  border-left: solid 1px #9c0039;
}

.info-box {
  border-top: 3px solid rgb(0, 129, 129);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  padding: 35px 80px 70px 25px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.info-box .modal-title {
  font-size: 26pt;
  line-height: 30pt;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.2em;
}
.info-box .tag {
  border-bottom: 1px solid #9c0039b4;
  color: #c0c0c0;
  display: block;
  font-size: 11pt;
  font-weight: 300;
  padding: 10px 0px 15px;
}
.info-box .detail {
  font-size: 13pt;
  line-height: 18pt;
  margin-top: 15px;
  font-weight: 200;
}
.info-box .mdi-open-in-new {
  font-size: 11pt;
  left: -10px;
  position: relative;
}
.info-box .modal-button {
  text-transform: uppercase;
  border: solid 2px #9c0039b4;
  bottom: 13px;
  color: #fff;
  cursor: pointer;
  font-size: 8pt;
  font-weight: 300;
  left: 25px;
  padding: 9px 25px;
  pointer-events: none;
  position: absolute;
  transition: all 0.5s;
  visibility: hidden;
  letter-spacing: 0.2em;
}
.carousel-transition {
  transition: 0.7s;
  -webkit-transition: 0.7s;
}
.modal-wrap {
  height: 90vh;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.info-box .modal-button.visible {
  pointer-events: initial;
  visibility: visible;
}
.info-box .modal-button:hover {
  background: rgba(255, 255, 255, 0.603);
  color: #e31b6d;
}

.modal-close {
  bottom: 15px;
  cursor: pointer;
  right: 5px;
  padding: 11px 30px;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .carousel-wrap {
    height: 240px;
  }
}

@media only screen and (min-width: 601px) {
  .carousel-wrap {
    height: 300px;
  }
  
  @media only screen and (min-width: 992px) {
    .carousel-wrap {
      height: 350px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .carousel-wrap {
      height: 400px;
    }
  }
  
}

  
@media only screen and (max-width: 830px) {

  .info-box {
    padding: 35px 40px 70px 25px;
  }
}