::-webkit-scrollbar {
  background: #000000;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #9c0039;
  border-radius: 4px;
}

.hexagon-wrapper {
  height: 155px;
  width: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  background: rgba(255, 255, 255, 0.71);
  margin: auto;
}

.hexagon {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  background: radial-gradient(
    circle,
    rgba(85, 85, 85, 1.26094187675070024) 11%,
    rgba(23, 23, 23, 1.8295693277310925) 59%,
    rgba(0, 0, 0, 1) 82%
  );
  margin: auto;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6vh;
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 19%,
    rgba(20, 20, 20, 1) 49%,
    rgba(28, 28, 28, 1) 74%
  );
  border-bottom: solid #9c0039 1px;
}

.logo {
  margin-left: 15px;
  height: 40px;
  width: 40px;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  background-color: #9c003979;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  -webkit-text-stroke-color: aqua;
  -webkit-text-stroke-width: 1px;
  transition: all 2.5s;
}

.logo:hover {
  -webkit-text-stroke-color: #9c0039;
  background-color: rgba(0, 255, 255, 0.486);
}

.logo h4 {
  letter-spacing: 0px;
  margin: auto;
  color: #000000;
  font-size: 15px;
  margin: auto;
}

.nav-links {
  display: flex;
  width: 60%;
  justify-content: space-around;
  vertical-align: middle;
  margin: initial;
  text-decoration: none;
  letter-spacing: 3px;
  font-size: 15px;
  text-transform: uppercase;
  color: #ebeded;
  font-weight: 200;
}
.nav-links .active {
  color: aqua;
}
.nav-links a {
  cursor: pointer;
}

.nav-links li {
  list-style: none;
}
.burger-wrapper {
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.highlight {
  font-family: "Raleway", sans-serif;
  color: aqua;
  font-weight: 500;
}

#home-title {
  position: absolute;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto !important;
  text-align: center;
  color: white;
  font-size: 36pt;
  line-height: 46pt;
  font-weight: 200;
}

.home {
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}

.container {
  padding: 60px 120px 140px;
}

.circle {
  height: 100px;
  width: 100px;
  clip-path: circle(50% at 50% 50%);
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.76234243697479) 27%,
    rgba(0, 255, 255, 0.5802696078431373) 54%,
    rgba(0, 255, 255, 0.33657212885154064) 59%,
    rgba(0, 255, 255, 0) 71%
  );
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
}
.animated-circle {
  clip-path: circle(0% at 50% 50%);
  transition: clip-path 0.5s;
  -webkit-transition: clip-path 0.5s;
}
.expand {
  clip-path: circle(50% at 50% 50%);
}

.title {
  font-family: "Raleway", sans-serif;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  color: white;
}
.title h1 {
  align-items: center;
  letter-spacing: 0.09em;
  font-size: 27pt;
}

.profile-pic-border {
  height: 245px;
  width: 245px;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  background-color: rgba(255, 255, 255, 0.71);
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile-wrappper {
  height: 240px;
  width: 240px;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  background: radial-gradient(
    circle,
    rgba(85, 85, 85, 1.26094187675070024) 11%,
    rgba(23, 23, 23, 1.8295693277310925) 59%,
    rgba(0, 0, 0, 1) 82%
  );
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile {
  display: block;
  height: 185px;
  width: 185px;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
}

.loading-text {
  font-size: 25pt;
  color: aqua;
  position: absolute;
  transition: all  3s;
  transition-delay: 1s;
}

.loading-text.fadeIn {
  color: #9c0039
}

#blurb {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.856);
  line-height: 1.6em;
  max-width: 630px;
  font-weight: 200;
}

#profile {
  margin: 50px auto 0px;
}

#special {
  font-weight: 400;
  font-size: 20px;
  color: #00d3d3;
  margin: 0;
  font-family: "Raleway", sans-serif;
}

.marker {
  color: #9c0039;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-size: 18pt;
  margin-top: 7px;
  margin-bottom: 4px;
  font-weight: 500;
}

.who {
  font-size: 34pt;
}

.profile-pic {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.animate-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bar {
  align-items: flex-end;
  background-color: #00000000;
  color: #fff;
  font-size: 7pt;
  height: 30px;
  margin: 0 0 24px 0;
  position: relative;
}

.bar .fill {
  left: 0;
  position: absolute;
  top: 0;
  background: #9c0039;
  opacity: 1;
  padding: 0;
}

.bar .tag {
  left: 0;
  position: absolute;
  top: 0;
  background-color: #55001f8e;
  color: #fff;
  height: 100%;
  width: 90px;
  font-size: 15px;
  letter-spacing: 0.05em;
}

.bar span {
  z-index: 1;
  font-size: 13px;
  letter-spacing: -0.02em;
}

.graph {
  padding: 20px 60px;
  margin-top: 80px;
}

.aws-btn {
  --button-default-height: 47px !important;
  --button-default-font-size: 14px !important;
  --button-default-border-radius: 6px !important;
  --button-horizontal-padding: 20px !important;
  --button-raise-level: 5px !important;
  --button-hover-pressure: 2 !important;
  --transform-speed: 0.185s !important;
  --button-primary-color: transparent !important;
  --button-primary-color-dark: #19191900 !important;
  --button-primary-color-light: #ffffff !important;
  --button-primary-color-hover: #00d3d3 !important;
  --button-primary-border: 1px solid white !important;
}

#skills-blurb {
  width: 75%;
  margin: auto;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.856);
  font-weight: 200;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.stars {
  background: #000 url(../images/stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(../images/twinkling.png) repeat top center;
  z-index: 1;
  -moz-animation: move-twink-back 500s linear infinite;
  -ms-animation: move-twink-back 500s linear infinite;
  -o-animation: move-twink-back 300s linear infinite;
  -webkit-animation: move-twink-back 500s linear infinite;
  animation: move-twink-back 300s linear infinite;
}

#gallery .card {
  height: 200px;
  max-width: 100%;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  width: calc(50vw - 220px);
}

.mix {
  display: inline-block;
}

#gallery .text {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#gallery .button {
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  border: 2px solid #9c0039;
  font-size: 13pt;
  margin: 0 auto;
  padding: 7px 0;
  width: 170px;
}

#gallery .text,
.button {
  color: aqua;
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 2;
}

#gallery .text .bold {
  font-size: 28pt;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.1em;
}
#gallery .text .language {
  color: white;
}

#gallery .mix .button:hover {
  background: rgba(0, 255, 255, 0.548);
  color: #9c0039;
  border: 2px solid rgb(0, 255, 255);
}

.footer-container {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 70px;
  text-align: center;
  padding: 15px;
  background-color: #000;
  border-top: 1px double #9c0039 ;
}

.credit-wrapper {
  color: #a8b2d1;
  line-height: 1;
  font-size: 12px;
}

.credit-wrapper a {
  color: #a8b2d1;
  line-height: 1;
  text-decoration: none;
}

.sociallinks-wrapper {
  display: block;
  width: 100%;
  max-width: 210px;
  color: #a8b2d1;
  margin: 0px auto 5px;
}

.sociallinks-wrapper ul {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.footer-icons {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background-color: black;
  transition: all 0.4s;
}

.footer-icons:hover {
  background-color: #9c0039;
}

.side-content-wrapper {
  width: 30px;
  position: fixed;
  bottom: 0px;
  z-index: 10;
}

.left {
  left: 40px;
  right: auto;
}

.right {
  left: auto;
  right: 40px;
}
.side-content-wrapper ul {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.right .content {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.content a {
  letter-spacing: 0.1em;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  margin: auto;
  padding: 10px;
  text-decoration: none;
  color: #9c0039;
  transition: all 0.2s;
}

.left .icons {
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  margin-bottom: 30px;
}

.left .icons:hover {
  background-color: #9c0039;
  margin-bottom: 40px;
}

.right a:hover {
  color: aqua;
  letter-spacing: 0.15em;
}

.side-lines {
  width: 1px;
  height: 130px;
  background: rgba(255, 255, 255, 0.705);
  margin-top: 30px;
}

.left .side-lines {
  margin-top: 5px;
}

.gallery-mobile {
  position: relative;
}

/* media screens / keyframes/ transforms below */

@media screen and (max-width: 1124px) {
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 60px 80px 20px;
  }
  #gallery .card {
    height: 240px;
    max-width: 100%;
    width: calc(50vw - 100px);
  }
  .nav-links {
    display: flex;
    width: 60%;
    justify-content: space-around;
  }
  #gallery .text .bold {
    font-size: 20pt;
  }
  .side-content-wrapper {
    display: none;
  }
  .graph {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    padding: 0%;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 6vh;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
    align-items: center;
    transform: translateX(-150%);
    transition: transform 0.5s ease, opacity 1s ease;
    -webkit-transition: transform 0.5s ease, opacity 1s ease;
    -webkit-transform: translateX(-150%);
    opacity: 0;
    z-index: -10;
  }
  .nav-links li {
    opacity: 0;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: solid #9c0039 1px;
  }
  .nav-links li a {
    width: 100%;
  }
  .burger-wrapper {
    display: flex;
  }
  .burger-icon {
    color: aqua;
    align-self: center;
    margin: auto;
    font-size: 20px;
  }
  #home-title {
    font-size: 25px;
    line-height: 33px;
  }
  #percent {
    padding: 0 2px 0 0;
  }

  #gallery .card {
    width: 100vw;
  }
  .container {
    padding: 60px 20px 20px;
  }
  #blurb {
    font-size: 18px;
    padding: 0px 20px;
  }

  #gallery .card {
    opacity: 0.7;
    margin-bottom: 50px;
    border-top: solid 1px aqua;
    border-bottom: solid 1px aqua;
  }
  #gallery .text {
    cursor: default;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    left: calc(50% - 35%);
    width: 70%;
    z-index: 0;
    border: 1px solid #9c0039;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  #gallery .button {
    display: none;
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.nav-active {
  transform: translateY(0%);
  opacity: 1;
}

.circle:hover {
  height: 135px;
  width: 135px;
}

@media screen and (min-width: 768px) {
  #gallery .mix:hover .card {
    opacity: 0.1;
  }
  #gallery .mix:hover .text {
    cursor: default;
    opacity: 1;
    top: 20%;
  }
  #gallery .mix:hover .button {
    bottom: 20%;
    opacity: 1;
  }
}
