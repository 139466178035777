.ui.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0em;
}

/*----------------------
Centered
-----------------------*/

.ui.centered.grid,
.ui.centered.grid > .row,
.ui.grid > .centered.row {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*-------------------
Columns
--------------------*/

/* Standard 16 column */

.ui.grid > .column:not(.row),
.ui.grid > .row > .column {
  position: relative;
  display: inline-block;

  vertical-align: top;
}

/*-------------------
Columns
--------------------*/

/* Vertical padding when no rows */

.ui.grid > .column:not(.row) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ui.grid > .row > [class*="four wide"].column,
.ui.grid > .column.row > [class*="four wide"].column,
.ui.grid > [class*="four wide"].column,
.ui.column.grid > [class*="four wide"].column {
  width: 25% !important;
}

.ui.grid > .row > [class*="sixteen wide"].column,
.ui.grid > .column.row > [class*="sixteen wide"].column,
.ui.grid > [class*="sixteen wide"].column,
.ui.column.grid > [class*="sixteen wide"].column {
  width: 100% !important;
}

@media only screen and (min-width: 992px) {
  .ui.grid > .row > [class*="four wide computer"].column,
  .ui.grid > .column.row > [class*="four wide computer"].column,
  .ui.grid > [class*="four wide computer"].column,
  .ui.column.grid > [class*="four wide computer"].column {
    width: 25% !important;
  }

  .ui.grid > .row > [class*="seven wide computer"].column,
  .ui.grid > .column.row > [class*="seven wide computer"].column,
  .ui.grid > [class*="seven wide computer"].column,
  .ui.column.grid > [class*="seven wide computer"].column {
    width: 33.75% !important;
  }
  .ui.grid > .row > [class*="nine wide computer"].column,
  .ui.grid > .column.row > [class*="nine wide computer"].column,
  .ui.grid > [class*="nine wide computer"].column,
  .ui.column.grid > [class*="nine wide computer"].column {
    width: 46.25% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.grid > .row > [class*="eight wide tablet"].column,
  .ui.grid > .column.row > [class*="eight wide tablet"].column,
  .ui.grid > [class*="eight wide tablet"].column,
  .ui.column.grid > [class*="eight wide tablet"].column {
    width: 50% !important;
  }
  .ui.grid > .row > [class*="sixteen wide tablet"].column,
  .ui.grid > .column.row > [class*="sixteen wide tablet"].column,
  .ui.grid > [class*="sixteen wide tablet"].column,
  .ui.column.grid > [class*="sixteen wide tablet"].column {
    width: 100% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .ui.grid > .row > [class*="sixteen wide mobile"].column,
  .ui.grid > .column.row > [class*="sixteen wide mobile"].column,
  .ui.grid > [class*="sixteen wide mobile"].column,
  .ui.column.grid > [class*="sixteen wide mobile"].column {
    width: 100% !important;
  }
}
.ui[class*="center aligned"].grid > .column,
.ui[class*="center aligned"].grid > .row > .column,
.ui.grid > [class*="center aligned"].row > .column,
.ui.grid > [class*="center aligned"].column.column,
.ui.grid > .row > [class*="center aligned"].column.column {
  text-align: center;
  -ms-flex-item-align: inherit;
  align-self: inherit;
}
.ui.centered.grid > .column:not(.aligned):not(.justified):not(.row),
.ui.centered.grid > .row > .column:not(.aligned):not(.justified),
.ui.grid .centered.row > .column:not(.aligned):not(.justified) {
  text-align: left;
}
