body {

  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: black;
    overflow-x: hidden;
}


#background_wrap {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background: url('./images/background.png'),
    linear-gradient(
      189deg,
      rgba(0, 0, 0, 1) 8%,
      rgba(0, 0, 0, 1) 49%,
      rgba(20, 20, 20, 1) 71%,
      rgba(28, 28, 28, 1) 89%
    );
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  #background_wrap {
   display: none;
  }
  
}
