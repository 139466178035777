#contact {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 250px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.ui.input {
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 40px;
}



.ui.fluid.input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.input > input {
  font-size: 25px;
  border-radius: 50px;
  margin: 0;
  max-width: 80%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 15px 25px 15px 25px;
  background: radial-gradient(
    circle,
    rgba(85, 85, 85, 0.26094187675070024) 11%,
    rgba(23, 23, 23, 0.8295693277310925) 59%,
    rgba(0, 0, 0, 0.8) 82%
  );
  border: 2px solid aqua;
  color: aqua;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  height: 50px;
  font-family: 'Montserrat', sans-serif;
}

.ui.fluid.input > input {
  width: 0 !important;
}

.form-textarea {
  resize: none;
  width: 80%;
  border-radius: 30px;
  border: 1px solid;
  height: 50px;
  padding: 15px 25px 15px 25px;
  font-size: 20px;
  border: 2px solid #9c0039;
  background: radial-gradient(
    circle,
    rgba(85, 85, 85, 0.26094187675070024) 11%,
    rgba(23, 23, 23, 0.8295693277310925) 59%,
    rgba(0, 0, 0, 0.8) 82%
  );
  color: #9c0039;
}

.form-textarea:focus {
  outline: none;
}

.form-button {
    float: right;
    border: solid 2px #9c0039b4;
    background-color: rgba(0, 0, 0, 0.356);
    cursor: pointer;
    font-size: 15pt;
    font-weight: 200;
    width: 160px;
    transition: all 0.5s;
    color: white;
    height: 50px;
    letter-spacing: 0em;
}

.disabled {
  border: solid 2px #000000b4;
  color: rgb(43, 43, 43);
  
}


.form-button:not(.disabled):hover {
  background-color: rgba(255, 255, 255, 0.582);
  color: #9c0039b4;
  letter-spacing: 0.4em;
}  

.tag {
  font-size: 20px;
  color: aqua;
}

.send-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(80% + 50px); 
  align-self: center;
}

textarea {
  font-family: 'Montserrat', sans-serif;
}

.message-style {
  color: white;
  font-size: 25px;
  align-items: center;
  display: flex;
  height: 50px;
}

.confirmation-icon {
  font-size: 40px;
   margin-right: 40px; 
}

@media screen and (max-width: 768px) { 
  .form-button{
    width: 100px;
    margin-left: 20px;
  }
  .confirmation-icon {
     margin-right: 20px; 
  }
  .message-style {
    font-size: 15px;
  }
}

