.ui.dimmer {
  display: none;
  position: absolute;
  top: 0em !important;
  left: 0em !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.678);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}

body.dimmable > .dimmer {
  position: fixed;
}

.dimmable{
  overflow: hidden;
}

.ui.page.dimmer {
  position: fixed;
  -webkit-transform-style: "";
  transform-style: "";
  -webkit-perspective: 2000px;
  perspective: 2000px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}

.ui.modal {
  width: 50%;
  top: 4vh;
  position: absolute;
  display: none;
  z-index: 1001;
  text-align: left;
  background: rgba(0, 0, 0, 0.829);
  border:  1px rgb(90, 90, 90);
  border-style: ridge;
  -webkit-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  will-change: top, left, margin, transform, opacity;
}

.ui.active.modal {
  display: block;
}

/* Modal Width */

@media only screen and (max-width: 700px) {
  .ui.modal {
    width: 100%;
    margin: 0em 0em 0em 0em;
  }
}


